
var progressPopup;

jQuery(function () {
  $('.btn-card-export').on('click', function() {
	  $(this).addClass('btn-loading');
	  onExportClick();
  });
})

function onExportClick() {
  if (areAllScriptsLoaded()) { onExportReady() }
  else loadAllScripts()
}

async function onExportReady() {
  const btns = document.getElementsByClassName("btn-loading"),
		btn = btns[0];
  const fileName = (btn.dataset.filename ? btn.dataset.filename : new Date().getTime().toString()) + ".pdf";
  const pdfContent = [];

  const processStart = new Date().getTime();
  console.log("Starting PDF gen", new Date());

	openProgressPopup();

  const mainElementGroup = $('.metric-groups').children().not('.metric-group-placeholder');
	const summaryElementGroup = $('.topic-content').children();

	const totalCount = mainElementGroup.length + summaryElementGroup.length;
	let step = 1;

  for (let i = 0; i < mainElementGroup.length; i++) {
    const element = mainElementGroup[i];
    pdfContent.push(await processPageElement(element));
		stepProgress(Math.ceil(step / totalCount * 100));
		step++;
  }
  for (let i = 0; i < summaryElementGroup.length; i++) {
    const element = summaryElementGroup[i];
	if(element.classList.contains('sh-trig')) continue;
    pdfContent.push(await processPageElement(element));
		stepProgress(Math.ceil(step / totalCount * 100));
		step++;
  }
  
  const docDefinition = {
	content: pdfContent,
	footer: (currentPage) => {
		const now = new Date();
		const date = now.getDate() + '. ' + (now.getMonth() + 1) + '. ' + now.getFullYear();
		return [
			{ text: currentPage, alignment: 'left', margin: [ 60, 0, 0, 0 ], fontSize: 8, },
			{ text: 'Údaje vytištěny ' + date + '. Zdroj: www.predikcetrhuprace.cz', 'link': 'https://www.predikcetrhuprace.cz', alignment: 'right', margin: [ 0, -10, 60, 0 ], fontSize: 8, },
		]
	},
	pageMargins: [60, 60, 60, 60],
	styles: {
      'sh-trig': {
        fontSize: 0,
	    lineHeight: 0
      },
    },
	pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
	  return currentNode.hasOwnProperty('style') &&
			 currentNode.style.length &&
			 currentNode.style[0] == 'html-h2' &&
			 followingNodesOnPage.length === 0;
	}
  };

  pdfMake.createPdf(docDefinition).download(fileName);

  console.log("Ending PDF gen", new Date());
  const processEnd = new Date().getTime();
  console.log("GENERATION OF PDF TOOK", ((processEnd - processStart) / 1000), "SECONDS");

	closeProgressPopup();
  
  btn.classList.remove("btn-loading");
}

/**
 * Rozdelovacia funkcia, ktora zisti ci sa v danej sekcii nachadza graf alebo text.
 * Zistujem to pomocou pritomnosti atributu "data-metric-group-id". text ho nema.
 * @param {HTMLElement} element 
 */
async function processPageElement(element) {
  const isElementWithText = element.attributes["data-metric-group-id"] == null;
  if (isElementWithText) return processPageText(element);
  else return await processPageGraph(element);
}

/** @param {HTMLElement} element */
function processPageText(element) {
  const config = {
    tableAutoSize: true,
	defaultStyles: {
		a: {
			color: '#004A98'
		},
		h1: {
		  fontSize: 16,
		  color: '#011545',
		},
		h2: {
		  fontSize: 16,
		  color: '#011545',
		},
		h3: {
		  fontSize: 15,
		  color: '#011545',
		},
		h3: {
		  fontSize: 14,
		  color: '#011545',
		},
		p: {
		  fontSize: 10,
		  color: '#6a7490',
		},
		span: {},
		ul: {
		  fontSize: 10,
		  color: '#6a7490',
		  markerColor: '#4b5263',
		},
		ol: {
		  fontSize: 10,
		  color: '#6a7490',
		  markerColor: '#4b5263',
		},
		li: {
		  fontSize: 10,
		  color: '#6a7490',
		},
	  }
  };
  
  const html = element.innerHTML,
		processedElement = htmlToPdfmake(html, config);
  if(processedElement.length) {
	 processedElement[processedElement.length-1].margin = [0,0,0,15];
  }
  return processedElement;
}

/** @param {HTMLElement} element */
async function processPageGraph(element) {
  const bestCanvasWidth = 480;
  const config = {
		width: 860,
  }

	const chart = element.querySelector('.metric-group-content');
	if(!chart) return [];

	for (const link of chart.querySelectorAll('a')) {
		link.removeAttribute('href');
	}

  // set css styles for export
  chart.classList.add('pdf-export');
  let dataUrl = undefined;
  try {
		dataUrl = await domtoimage.toPng(chart, config);
  } catch (error) {
    console.error(error);
  }
  // remove css styles after export
  chart.classList.remove('pdf-export');
  const processedElements = [];
  
  if(dataUrl == undefined) return processedElements;

  // add metric group header
  const head = element.querySelector('.metric-group-head');
  if(head != undefined) {
		const headConfig = {
			defaultStyles: {
				h2: {
				fontSize: 16,
				marginTop: 0,
				marginBottom: 0,
				color: '#011545',
				},
			}
	  };
		const pdfHead = htmlToPdfmake(head.outerHTML, headConfig);
		processedElements.push(pdfHead);
  }
  
  processedElements.push({
		image: dataUrl,
		width: bestCanvasWidth,
		margin: [1, 7, 0, 25]
  });

  return processedElements;
}

/* Progress bar */

function openProgressPopup() {
	if(progressPopup == undefined) {
		const popupHtml =
		'<div class="progress-overlay">' +
			'<div class="progress-overlay-inner">' +
				'<div class="progress-box">' +
					'<div class="progress-circle" data-value="0">' +
						'<span class="progress-label">0</span>' +
						'<div class="progress-left-clipper">' +
							'<div class="progress-left-side"></div>' +
							'<div class="progress-right-side progress-value"></div>' +
						'</div>' +
					'</div>' +
				'</div>' +
			'</div>' +
		'</div>';
		progressPopup = document.createElement('div');
		progressPopup.innerHTML = popupHtml;
	}

	document.body.appendChild(progressPopup);
}

function closeProgressPopup() {
	if(progressPopup && progressPopup.parentNode) {
		stepProgress(0);
		progressPopup.parentElement.removeChild(progressPopup);
	}
}

/**
 * @param {int} percents
 */
function stepProgress(percents) {
	if(progressPopup == undefined || !progressPopup.querySelector('.progress-circle')) return;

	const circle = progressPopup.querySelector('.progress-circle');
	const label = progressPopup.querySelector('.progress-label');
	circle.setAttribute('data-value', percents);
	if(percents > 50) {
		circle.classList.add('progress-circle-over50')
	}
	else {
		circle.classList.remove('progress-circle-over50')
	}

	if(label) {
		label.innerHTML = percents;
	}
}

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  --- 

/**
 * https://javascript.info/script-async-defer
 * https://stackoverflow.com/questions/16230886/trying-to-fire-the-onload-event-on-script-tag
 * @param {string} scriptName
 */
function loadDynamicScript(scriptName) {
  if (gExportRoot[scriptName] == null) {
    console.error("Error @ exporting.js:loadDynamicScript");
    return;
  }
  if (gExportRoot[scriptName].requested) return;
  gExportRoot[scriptName].requested = true;

  const script = document.createElement('script');
  script.onload = function (value) {
    gExportRoot[scriptName].loaded = true;
    if (areAllScriptsLoaded()) {
      onExportReady()
    }
  };
  script.src = gExportRoot[scriptName].url;
  script.async = false;
  document.body.append(script);
}

function loadAllScripts() {
  const scripts = fun.mapKeys(gExportRoot);
  for (let i = 0; i < scripts.length; i++) {
    loadDynamicScript(scripts[i])
  }
}

function areAllScriptsLoaded() {
  let loaded = true;
  const scripts = fun.mapKeys(gExportRoot);
  for (let s = 0; s < scripts.length; s++) {
    if (!gExportRoot[scripts[s]].loaded) loaded = false;
  }
  return loaded;
}

/** @param {string} url */
function dynamicLibrary(url) {
  return { url: url, requested: false, loaded: false }
}

/** @type {{ [key: string]: { url: string; requested: boolean; loaded: boolean } }} */
const gExportRoot = {
  pdfmake:        dynamicLibrary("/static/js/exporting/pdfmake.min.js"),
  vfs_fonts:      dynamicLibrary("/static/js/exporting/vfs_fonts.min.js"),
  htmlToPdfmake:  dynamicLibrary("/static/js/exporting/browser.js"),
	domToImage:    dynamicLibrary("/static/js/exporting/dom-to-image.min.js"),
}
