/** Helper object with generic functions */
const fun = {
	/**
	 * Get JS object keys in a list.
	 * @param {Map<string, any>} param
	 * @returns {string[]}
	 */
	mapKeys: function (param) {
		return (param == undefined) ? [] : Object.getOwnPropertyNames(param);
	},
	/**
	 * Does the JS object contain parameter?
	 * @param {{}} object
	 * @param {string} key
	 */
	hasKey: function (object, key) {
		return object.hasOwnProperty(key);
	},
	/**
	 * Parse float with optional replacement of comma with dot - for right parsing.
	 * @param {string} value
	 */
	parseFloat: function (value) {
		return parseFloat(value.replace(",", "."));
	},
};

/**
 * Download JSON from given URL using Ajax.
 * @param {string} url odkaz na API endpoint pre ziskanie dat
 * @param {(string) => void} onDataReceived callback pre vratenie dat do hlavneho toku kodu
 * @param {(string, any) => void} onError callback v pripade znamej alebo neznamej poruchy
 */
function pullJsonData(url, onDataReceived, onError) {
	// https://www.w3schools.com/jquery/ajax_getjson.asp
	$.getJSON(url, function (data, status, xhr) {
		const result =
			status === "success" && xhr.status === 200 && xhr.readyState === 4;
		if (result) onDataReceived(data);
		else onError(status, xhr);
	});
}

/**
 * Download JSON from given URL using Ajax and POST method (used for metric comments)
 * @param {string} url - for API endpoint
 * @param {string} dataToSend - a plain object or string that is sent to the server with the request
 * @param {(string) => void} onDataReceived
 */
function pullJsonDataByPOST(url, dataToSend, onDataReceived, onError) {
	// https://www.w3schools.com/jquery/ajax_getjson.asp
	$.post(url, dataToSend, function (data, status, xhr) {
		const result =
			status === "success" && xhr.status === 200 && xhr.readyState === 4;
		if (result) onDataReceived(data);
		else onError(status, xhr);
	}, 'json');
}

// function downloadBinaryData(url, onDataReceived, onError) {
//   var oReq = new XMLHttpRequest();
//   oReq.open("GET", url, true);
//   oReq.responseType = "arraybuffer";
//   oReq.onload = function (oEvent) {
//     const arrayBuffer = oReq.response;
//     const str = String.fromCharCode.apply(null, new Uint16Array(arrayBuffer))
//     console.log(arrayBuffer)
//     console.log(str)
//     onDataReceived(str)
//     // if you want to access the bytes:
//     // var byteArray = new Uint8Array(arrayBuffer);
//     // ...
//     // If you want to use the image in your DOM:
//     // var blob = new Blob([arrayBuffer], {type: "image/png"});
//     // var url = URL.createObjectURL(blob);
//     // someImageElement.src = url;
//     // whatever...
//   };
//   oReq.send();
// }
