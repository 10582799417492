
function initVerificationFormValidation() {
    // add this custom validator which requires an input to be empty
    $.validator.addMethod('empty', function(value, element) {
        return (value === '');
    }, 'Toto pole musí zůstat prázdné!');

	$.validator.addMethod('emptyWithTrendSelectDependency', function(value, element) {
        return (value === '');
    }, 'Toto pole musí zůstat prázdné!');

    const trendSelectDependency = (row) => {
        let trendField = $('[name="souhlasi-trend-poptavky-po-danem-vzdelanostnim-klastru[' + row + ']"], [name="souhlasi-trend[' + row + ']"]');
        return (
            trendField.val() === 'neodpovídá trend'
            ||
            trendField.val() === 'souhlasí trend, ale ne výše změny'
        );
    };

    const trendSelectDependencyNotEmpty = (row) => {
        let trendField = $('[name="souhlasi-trend-poptavky-po-danem-vzdelanostnim-klastru[' + row + ']"], [name="souhlasi-trend[' + row + ']"]');
        return (
            trendField.val() === 'neodpovídá trend'
        );
    };

    const emptyAssumedValueDependency = (row) => {
        let assumedValueField = $('[name="predpokladana-hodnota[' + row + ']"]');
        return (
            assumedValueField.is(':blank')
        );
    };

    const emptyPredictionYearGroupDependency = (row) => {
        let empty = true;

        for (let i = 1; i <= 5; i++) {
            empty = empty && $('[name="predikcni-rok-' + i + '[' + row + ']"]').is(':blank');
        }
            
        return empty;
    };

    const rowValidationRules = (row) => {
        const rules = {
            ['souhlasi-trend-poptavky-po-danem-vzdelanostnim-klastru[' + row + ']']: {
                required: true,
            },
            ['souhlasi-trend[' + row + ']']: {
                required: true,
            },
            ['predpokladana-hodnota[' + row + ']']: {
                required: {
                    depends: () => trendSelectDependencyNotEmpty(row) && emptyPredictionYearGroupDependency(row),
                },
                empty: {
                    depends: () => trendSelectDependency(row) && !emptyPredictionYearGroupDependency(row),
                },
				emptyWithTrendSelectDependency: {
                    depends: () => !trendSelectDependency(row),
                },
            },
        };
            
        for (let i = 1; i <= 5; i++) {
            rules['predikcni-rok-' + i + '[' + row + ']'] = {
                require_from_group: {
                    param: [1, '[name^=predikcni-rok][name$="[' + row + ']"]'],
                    depends: () => trendSelectDependencyNotEmpty(row) && emptyAssumedValueDependency(row),
                },
                empty: {
                    depends: () => trendSelectDependency(row) && !emptyAssumedValueDependency(row),
                },
				emptyWithTrendSelectDependency: {
                    depends: () => !trendSelectDependency(row),
                },
            };
        };

        return rules;
    };

    const rowValidationRulesMessages = (row) => {
        const messages = {
            ['souhlasi-trend-poptavky-po-danem-vzdelanostnim-klastru[' + row + ']']: {
                required: "Hodnota musí být vyplněna!",
            },
            ['souhlasi-trend[' + row + ']']: {
                required: "Hodnota musí být vyplněna!",
            },
            ['predpokladana-hodnota[' + row + ']']: {
                required: "Předpokládaná hodnota nebo alespoň jeden predikční rok musí být vyplněný!",
                empty: "Předpokládaná hodnota a predikční rok nesmí být vyplněny zároveň!",
				emptyWithTrendSelectDependency: "Vybraná volba v poli Souhlasí trend a zvolená hodnota nejsou v souladu",
            },
        };

        for (let i = 1; i <= 5; i++) {
            messages['predikcni-rok-' + i + '[' + row + ']'] = {
                require_from_group: "Předpokládaná hodnota nebo alespoň jeden predikční rok musí být vyplněný!",
				empty: "Předpokládaná hodnota a predikční rok nesmí být vyplněny zároveň!",
                emptyWithTrendSelectDependency: "Vybraná volba v poli Souhlasí trend a zvolená hodnota nejsou v souladu",
            };
        };

        return messages;
    };

    $('form.form-verification').each(function () {
        if ($(this).attr('data-validation-initialized')) {
            return;
        }

        let validationRules = {
            rules: {},
            messages: {},
        };

        for (let row = 0; row < $(this).find('.verification-table-topic-col').length; row++) {
            Object.assign(validationRules.rules, rowValidationRules(row));
            Object.assign(validationRules.messages, rowValidationRulesMessages(row));
        }

        $(this).validate(validationRules);

        $(this).attr('data-validation-initialized', '1');
    });

}