var metricGroups = [],
	metricGroupsContainer,
	metricGroupsPlaceholder,
	loaderOverlay;

jQuery(function () {
	// global variables elements
	metricGroupsContainer = $('#metric-groups-wrapper');
	metricGroupsPlaceholder = $('.metric-group-placeholder');
	loaderOverlay = $('.loader-overlay');

	if (!metricGroupsContainer.length) return;

	/* fixed predefined region/filter (topic) - topic detail pages */

	//frontend region
	const region = parseInt(metricGroupsContainer.data('region'));
	if(!isNaN(region)) gRegions = [region];
	//backend region
	const backendRegion = parseInt(metricGroupsContainer.data('backend-region'));
	if(!isNaN(backendRegion)) gRegionsBackendIds = [backendRegion];

	//frontend topic
	const filter = metricGroupsContainer.data('filter');
	const filterId = parseInt(metricGroupsContainer.data('filter-id'));
	if(filter != undefined && !isNaN(filterId)) {
		gFilters[filter] = { values: [filterId] }
	}

	const relatedFilters = metricGroupsContainer.data('filters');
	if(relatedFilters != undefined) {
		addRelatedFilters(relatedFilters);
	}

	//backend topic
	const backendFilter  = parseInt(metricGroupsContainer.data('backend-filter'));
	if(!isNaN(backendFilter)) gFiltersBackendIds = [backendFilter];

	/* comparison form */
	$('.comparison-form').each(function() {
		const form = $(this);
		initComparisonForm(form);
		form.submitForm({ refreshMetricGroups: 1, refreshComments: 0 });
	});

	/* save selected values to global variables */

	$('.topics-select').each(function() {
		const topicsSelect = $(this);
		const selectedOptions = topicsSelect.find("option:selected");

		/** @type {string} */
		const filter = topicsSelect.data('filter');

		if(filter != null) { // sector, profesion, education topic filter
			setFilters(filter, topicsSelect);
		}
		else { // region topic filter
			gRegions = topicsSelect.val().map(function(el) { return parseInt(el) });
			gRegionsBackendIds = (selectedOptions.length) ? selectedOptions.get().map(function(el) { return el.getAttribute('data-backend-id'); }) : [];
		}

		topicsSelect.on('selection-save', function() {
			const selectedOptions = topicsSelect.find("option:selected");

			if(filter != null) {
				setFilters(filter, topicsSelect);
			}
			else {
				gRegions = topicsSelect.val().map(function(el) { return parseInt(el)});

				const selectedOptions = topicsSelect.find("option:selected");
				gRegionsBackendIds = (selectedOptions.length) ? selectedOptions.get().map(function(el) { return el.getAttribute('data-backend-id'); }) : [];
			}
		});
	});
	
	$('.topics-selects').on('all-selection-save', function() {
		// re-render all charts after regions/topics selected
		// prerenderovat vsetky grafy - pres jquery each na vsech existujicich elementech - v metricDataRoot muzou byt i metric groups, ktere uz na strance nejsou (byly odebrany)
		$('.metric-group').each(function() {
			initMetric($(this));
		});
	});

	$('.metric-group').each(function() {
		initMetricGroup($(this)); // init charts in all group boxes
	});

});

/** @param {string} filter */
/** @param {JQuery<HTMLElement>} topicsSelect */
function setFilters(filter, topicsSelect) {
	// frontend filters
	gFilters = {};
	gFilters[filter] = { values: topicsSelect.val().map(function(el) { return parseInt(el) }) }

	const selectedOptions = topicsSelect.find('option:selected');
	selectedOptions.each(function() {
		let filters = $(this).data('filters');
		if(filters != undefined) {
			addRelatedFilters(filters);
		}
	});

	// backend filters
	gFiltersBackendIds = (selectedOptions.length) ? selectedOptions.get().map(function(el) { return el.getAttribute('data-backend-id'); }) : [];
}

/** @param { {code: string, values: number[]}[]} filters */
function addRelatedFilters(filters) {
	if(!filters.length) return;

	filters.forEach(function(filter) {
		if(!Array.isArray(filter.values)) return;

		if(gFilters[filter.code] == undefined) {
			gFilters[filter.code] = { values: filter.values.map(function(el) { return parseInt(el) }) }
		}
		else {
			gFilters[filter.code]['values'].push(filter.values.map(function(el) { return parseInt(el) }));
		}
	});
}

/** @param {JQuery<HTMLElement>} form */
function initComparisonForm(form) {
	if (form == null) form = $('.comparison-form');
	if (form.length) {
		const perspectives = form.find('input[name="perspective"]'),
		      metricgroups = form.find('input[name="metricgroups[]"]');

		perspectives.off('change');
		perspectives.on('change', function() {
			form.submitForm({ refreshMetricGroups: 1, refreshComments: 0 });
		});

		metricgroups.off('change');
		metricgroups.on('change', function() {
			form.submitForm({ refreshMetricGroups: 0, refreshComments: 0 });
		});
	}
}

/** @param {string} body */
function addMetricGroup(body) {
	if(metricGroupsPlaceholder.length && metricGroupsPlaceholder.is(':visible')) metricGroupsPlaceholder.hide();

  const metricGroupElement = $(body);
	metricGroupsContainer.append(metricGroupElement);
	metricGroups.push(metricGroupElement);
	initMetricGroup(metricGroupElement);
}

/** @param {number} metricGroupId */
function removeMetricGroup(metricGroupId) {
	const form = $('.comparison-form');
	if (form.length) {
		const metricgroupInput = form.find('input[name="metricgroups[]"][value='+metricGroupId+']');
		if(metricgroupInput.length) {
			metricgroupInput.prop('checked', false);
			metricgroupInput.trigger('change');
		}
	}
}

/**
 * Remove unchecked metric groups 
 * @param {string[]} selectors 
 */
function cleanMetricGroups(selectors) {
	metricGroups.forEach(function(metricGroupEl, i) {
		const metricGroup = $(metricGroupEl),
			id = metricGroup.attr('id');
		if (selectors.indexOf('#' + id) === -1) {
			metricGroup.remove();
			metricGroups.splice(i, 1);
			unsetChartData(metricGroup);
		}
	});

	// show placeholder if left no metric groups
	if(!metricGroups.length) {
		metricGroupsPlaceholder.fadeIn();
	}
}

/** @param {JQuery<HTMLElement>} metricGroupWrapper */
function initMetricGroup(metricGroupWrapper) {
	const metricSelect = metricGroupWrapper.find('.metric-group-select'),
        metricGroupClose = metricGroupWrapper.find('.metric-group-close');

	if(!metricSelect.length) return;
	initSelect(metricSelect);
	initMetric(metricGroupWrapper);

	// re-render on metric select change
	metricSelect.on('change', function(e) {
		initMetric(metricGroupWrapper);
	});

	if(metricGroupClose.length) {
		metricGroupClose.on('click', function() {
			/** @type {number} */
			const metricGroupId = metricGroupWrapper.data('metric-group-id');
			removeMetricGroup(metricGroupId);
		});
	}
}

/** @param {JQuery<HTMLElement>} metricGroupWrapper */
function initMetric(metricGroupWrapper) {
	const metricTable = metricGroupWrapper.find('.highcharts-data-table'),
		  metricMap =  metricGroupWrapper.find('.highcharts-map');
	if (metricTable.length) metricTable.remove();
	if (metricMap.length) metricMap.remove();
	metricGroupWrapper.data('initialized', false);

	initChart(metricGroupWrapper);
	initMetricDetails(metricGroupWrapper);
	initMetricControls(metricGroupWrapper);
}

/** @param {JQuery<HTMLElement>} metricGroupWrapper */
function initMetricControls(metricGroupWrapper) {
	const metricDownload = metricGroupWrapper.find('.metric-download'),
		    metricSwitchChart = metricGroupWrapper.find('.metric-chart'),
		    metricSwitchTable = metricGroupWrapper.find('.metric-table'),
		    metricSwitchMap = metricGroupWrapper.find('.metric-map'),
		    metricAddComment = metricGroupWrapper.find('.metric-add-comment');
	const switchButtons = [
		{ button: metricSwitchChart, elementSelector: '.metric .highcharts-container' },
		{ button: metricSwitchTable, elementSelector: '.highcharts-data-table' },
		{ button: metricSwitchMap, elementSelector: '.highcharts-map' }
	];

	metricGroupWrapper.off('chart-render');
	metricGroupWrapper.on('chart-render', function(event, chart){
		if(!metricGroupWrapper.data('initialized')) {
			switchButtons.forEach(function(switchItem) {
				const switchButton = switchItem.button,
					  element = metricGroupWrapper.find(switchItem.elementSelector);
			    if(!switchButton.length) {
					if(element.length) {
						element.hide();
					}
					return;
				}
				if(!element.length) {
					switchButton.hide();
				}

				switchButton.off('click');
				switchButton.on('click', function() {
					switchButtons.forEach(function(item) {
						const btn = item.button,
							  el = metricGroupWrapper.find(item.elementSelector);
						  if(btn.length && el.length) {
							  btn.removeClass('btn-active');
							  el.hide();
						  }
					});
					switchButton.addClass('btn-active');
					element.fadeIn();
				});
			});

			// download metric data
			if(metricDownload.length) {
				metricDownload.off('click');
				metricDownload.on('click', function() {
					chart.downloadCSV();
				});
			}
		}

		if(metricSwitchChart.length) {
			metricSwitchChart.click();
		}

		metricGroupWrapper.data('initialized', true);
	});

	// open comment modal
	if(metricAddComment.length) {
		if(metricAddComment.attr('href') === undefined) return;

		initMetricCommentModal(metricAddComment, metricGroupWrapper);
	}
}

/** @param {JQuery<HTMLElement>} metricGroupWrapper */
function initMetricDetails(metricGroupWrapper) {
	const selectedOption = getSelectedMetric(metricGroupWrapper);
	if(!selectedOption.length) return;

	/** @type {string} */
	const metricId = selectedOption.val();
	/** @type {string} */
	const metricCommentsUrl = selectedOption.data('comments-url');

	loadComments(metricGroupWrapper, metricCommentsUrl);
	showMetricInfo(metricGroupWrapper, metricId);
}

/**
 * Show comments for selected regions and metric
 * @param {JQuery<HTMLElement>} metricGroupWrapper 
 * @param {string} metricCommentsUrl 
 */
function loadComments(metricGroupWrapper, metricCommentsUrl) {
	const commentsWrapper = metricGroupWrapper.find('.metric-comments');
	if(!commentsWrapper.length || (metricCommentsUrl == undefined)) return;

	commentsWrapper.html('');

	const filtersData = {
		regions: Object.values(gRegionsBackendIds),
		topics: Object.values(gFiltersBackendIds)
	}

	pullJsonDataByPOST(
		metricCommentsUrl,
		filtersData,
		function (receivedData) {
			commentsWrapper.html(receivedData.comments);

			const commentModalTriggers = $('.metric-comment-modal-trigger');
			initMetricCommentModal(commentModalTriggers);
		},
		function (error, xhr) {
			console.error("pull comments data Exception", error, xhr);
		}
	);
}

/**
 * Show comments for selected regions and metric
 * @param {JQuery<HTMLElement>} metricGroupWrapper 
 * @param {string} metricId 
 */
function showMetricInfo(metricGroupWrapper, metricId) {
	metricGroupWrapper.find('.metric-info').each(function() {
		let commentMetric = $(this).data('metric');	
		if (commentMetric == metricId) {
			$(this).show();
		}
		else {
			$(this).hide();
		}
	});
}

/**
 * Show comments for selected regions and metric
 * @param {JQuery<HTMLElement>} metricGroupWrapper 
 * @param {string} metricId 
 */
function getSelectedMetric(metricGroupWrapper) {
	return metricGroupWrapper.find("option:selected");
}
