$(document).ready(function ($) {
	initMetricGroupTogglers();
	
	$('.sh-trig').toggler({slide: true});
});

function initMetricGroupTogglers() {
	$('.filter-group-name').unbind('click');
	$('.filter-group-name').toggler({slide:true});
}

function scrollToContent() {
	var offset = 0;
	if (settings.offsetScroll) {
		offset = settings.offsetScroll;
	}
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: $(content).offset().top + offset}, 500, function () {
		//window.location.hash = content.attr('id');
	});
}

function scrollToTrigger() {
	var offset = 0;
	if (settings.offsetScroll) {
		offset = settings.offsetScroll + 200;
	}
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset}, 500, function () {
		//window.location.hash = content.attr('id');
	});
}

(function ($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false,
			checkboxReverse: false
		}, settings);

		$(this).each(function () {

			var trigger = $(this),
				content = $('#' + trigger.data('content'));

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					var checked = $(this).is(':checked');
					if(settings.checkboxReverse) checked = !checked;
					if (checked) {
						content.fadeIn(settings.showSpeed);
					} else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					if (trigger.is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				trigger.on('click', function () {
					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
						} else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
						}

						$(this).addClass('active').find('.text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					} else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
						} else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
						}
						$(this).removeClass('active').find('.text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					return false;
				});
			}
			// Hide on document click
			if (settings.hideOnDocumentClick) {
				$(document).bind('click', function (e) {
					if (!$(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) {
						content.fadeOut();
					}
				});
			}
			// Escape button
			if (settings.hideOnEscape) {
				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						content.fadeOut();
					}
				});
			}
		});
	};
})(jQuery);
