jQuery(function(){
	$('.map-region').each(function(){
		var region = $(this),
			link = region.find('.map-link');
		if(link.length) {
			link.on('click', function(e){
				e.stopPropagation();
			});
			region.on('click', function(e){
				link[0].click();
			});
		}
	});
});