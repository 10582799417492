jQuery(function(){
	var body = $('body'),
		dropdownTriggers = $('.dropdown-trig'),
		searchTrigger = $('.search-trig'),
		menuTrigger = $('.menu-trigger'),
		searchInput = $('#search_form_query');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		hideDropdowns();
		body.toggleClass('menu-open');
	});


	dropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('dropdown'),
			dropdown = $('#' + elId);
		if(dropdown.length) {
			trigger.on('click', function() {
					var wasOpened = dropdown.hasClass('dropdown-open');
					$('.dropdown').removeClass('dropdown-open').slideUp(100);
					$('.dropdown-trig').removeClass('dropdown-trig-active');
					body.removeClass('menu-open');

					if(!wasOpened) {
						dropdown.addClass('dropdown-open').slideDown(200, function() {
							if(trigger.hasClass('search-trig') && searchInput.length) {
								searchInput.first().focus();
							}
						});
						trigger.addClass('dropdown-trig-active');
						trigger.closest('ul').addClass('dropdown-wrap-active');
					}

					if($('.dropdown-open').length) {
						body.addClass('dropdown-opened');
					}
					else {
						body.removeClass('dropdown-opened');
					}
				
			});
		}
	});

	$(document).bind('click', function (e) {
		if (!$('.main-nav, .secondary-nav, .x-layer').is(e.target) && $('.main-nav, .secondary-nav, .x-layer').has(e.target).length === 0) {
			hideDropdowns();
		}
	});

	function hideDropdowns() {
		$('.dropdown').slideUp(200, function() { $('.dropdown').removeClass('dropdown-open'); });
		$('.dropdown-trig').removeClass('dropdown-trig-active');
		body.removeClass('dropdown-opened');
	}
 });
