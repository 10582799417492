jQuery(function () {
	const commentModalTriggers = $('.metric-comment-modal-trigger');
	initMetricCommentModal(commentModalTriggers);
});

/**
 * Init modal trigger for adding metric comment
 * @param {JQuery<HTMLElement>} modalTrigger 
 * @param {JQuery<HTMLElement>} metricGroupWrapper 
 */
function initMetricCommentModal(modalTrigger, metricGroupWrapper) {
	modalTrigger.off('click');
	modalTrigger.on('click', function(e) {
		e.preventDefault();
		if(modalTrigger.attr('href') === undefined) return;

		openCreateCommentModal(modalTrigger, metricGroupWrapper);
	});
}

/**
 * Load modal content for adding metric comment
 * @param {JQuery<HTMLElement>} modalTrigger 
 * @param {JQuery<HTMLElement>} metricGroupWrapper 
 */
function openCreateCommentModal(modalTrigger, metricGroupWrapper) {
	const selectedOption = (metricGroupWrapper != undefined) ? getSelectedMetric(metricGroupWrapper) : null,
		modalUrl = modalTrigger.attr('href'),
		reloadPage = modalTrigger.data('page-reload');

	const data = {
		metric: (selectedOption != null) ? selectedOption.val() : ''
	}

	const onCommentModalOpen = function(receivedData, elements) {
		if(receivedData.status == 'success' && elements.length) {
			const modal = elements[0];

			// init form
			const form = modal.find('form');

			if(!form.length) return;

			const onCommentSent = function(data) {
				$.fancybox.close(modal);

				if(reloadPage) {
					location.reload();
				}
			};
			const onCommentFailed = function(xhr, status) {
				console.error("load comment modal exception", status, xhr);
			};

			form.on('submit', function () {
				$(this).myAjax($(this).attr('data-to-inject'), onCommentSent, onCommentFailed);
				return false;
			});

			// init selects
			const selects = modal.find('.select');
			if(!selects.length) return;

			selects.each(function() {
				initSelect($(this));
			});

			// update filters when selected metric changed
			const metricSelect = modal.find('.metric-comment-form-metric');
			if(!metricSelect.length) return;

			//metricSelect.unbind('change', handleCommentFormMetricUpdate);
			//metricSelect.bind('change', {modal: modal, metricSelect: metricSelect, url: modalUrl}, handleCommentFormMetricUpdate);
			metricSelect.on('change', function() {
				const filtersData = {
					metric: metricSelect.val(),
					filtersUpdate: true
				};
				modalTrigger.myAjax(filtersData,
					function(receivedData, elements) {
						if(receivedData.status == 'success' && elements.length) {
							const filtersWrapper = elements[0];

							const selects = filtersWrapper.find('.select');
							if(!selects.length) return;

							selects.each(function() {
								initSelect($(this));
							});
						}

					},
					function() {
						console.error("load comment modal filters exception", status, xhr);
				});
			});
		}
		else {
			console.error("load comment modal exception");
		}
	};
	const onCommentModalFailed = function(xhr, status) {
		console.error("load comment modal exception", status, xhr);
	};

	modalTrigger.myAjax(data, onCommentModalOpen, onCommentModalFailed);

}