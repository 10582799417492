/** @param {JQuery<HTMLElement>} element */
function initChart(element) {
	const metricGroupWrapper = element,
		metricGroupWrapperId = element.attr("id"),
		metricWrapper = element.find(".metric"),
		metricSelect = metricGroupWrapper.find(".metric-group-select");

	let mapWrapper = metricGroupWrapper.find(".highcharts-map");
	if (!mapWrapper.length) {
		mapWrapper = $('<div class="highcharts-map"></div>');
		metricWrapper.after(mapWrapper);
	}

	if (!metricWrapper.length || !metricSelect.length) return;

	const seletedOption = metricSelect.find("option:selected");
	if (seletedOption.length) {
		const selectedOptionId = seletedOption.val().toString();
		// check if data is loaded for selected metric
		if (
			fun.hasKey(gMetricDataRoot, metricGroupWrapperId) &&
			gMetricDataRoot[metricGroupWrapperId].metricId === selectedOptionId
		) {
			// ---> data already loaded
			renderChart(
				metricWrapper,
				mapWrapper,
				metricGroupWrapper,
				gMetricDataRoot[metricGroupWrapperId]
			);
		} else {
			// ---> load and store data
			metricWrapper.addClass("metric-placeholder"); // add spinner animation
			// load data
			pullJsonData(
				seletedOption.data("url"),
				/** onDataReceived function.
				 * @param {any} received */
				function (received) {
					gMetricDataRoot[metricGroupWrapperId] = {
						id: metricWrapper.attr("id"),
						metricId: selectedOptionId,
						metricData: received,
					};
					metricWrapper.removeClass("metric-placeholder"); // remove spinner animation
					renderChart(
						metricWrapper,
						mapWrapper,
						metricGroupWrapper,
						gMetricDataRoot[metricGroupWrapperId]
					);
				},
				/** onError function.
				 * @param {"notmodified"|"error"|"timeout"|"parsererror"} error
				 * @param {JQuery.jqXHR<any>} xhr */
				function (error, xhr) {
					// remove spinner animation
					metricWrapper.removeClass("metric-placeholder");
					console.error("pullData Exception", error, xhr);
				}
			);
		}
	}
}

/** @param {JQuery<HTMLElement>} element */
function unsetChartData(element) {
	const metricGroupWrapperId = element.attr("id");
	if (gMetricDataRoot.hasOwnProperty(metricGroupWrapperId)) {
		delete gMetricDataRoot[metricGroupWrapperId];
	}
}

function unsetAllData() {
	$(".metric-group").each(function () {
		unsetChartData($(this));
	});
}

/**
 * Series:
 * => pokud nejsou v metadatech žádné číselníky -> jsou to rovnou hlavní series
 *   => vybrat požadované regiony a vyzobat/seřadit data podle hodnoty x (rok)
 *     => pokud je hlavní serie jedna, název nastavit jako popisek osy y grafu
 *     => pokud je hlavních series více, název zůstavá nezměněn
 *   => pokud jsou definované filtry
 *     => brát v úvahu jen vybrané regiony
 *     => každá serie se "rozdělí" na více
 *       => pokud je filtr jeden -> podle něj se rozdělí do n series (n je počet hodnot v číselníku)
 *          + popisek serie do legendy potom bude název z číselníku
 *       => pokud jich bude více budou se muset udělat kombinace obou číselníků... tj n x m series
 *          + popisek do legendy potom bude kombinace názvů z obou číselníků, např. oddělené pomlčkou
 * @param {{
 * metadata: {
 *   domain: string[]
 *   domainTitle: string
 * 
 *   prediction: string | number
 *   currentYear: string
 * 
 *   chartType: string
 *   exportFilename: string
 *   note: string
 *   height: number
 * 
 *   requireRegionSelection: boolean
 *   showMapView: boolean
 *   stacked: boolean
 * 
 *   filters: { code: string; name: string; list: { [key: string]: string } }[]
 *   regions: { [key: number]: string }
 *   mapRegions: { [key: string]: string }
 * }
 * data: {
 *   name: string
 *   regions: { [key: string]: string; x: string; y: string }[][]
 * }[]
 * }} metric
 * @param {number[]} chosenRegions
 * @param {{ [key: string]: { values: number[] } }} chosenFilters
 */
function processMetricData(metric, chosenRegions, chosenFilters) {
	/// https://www.highcharts.com/demo/column-stacked-and-grouped
	/// https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped
	/** @type {{ name: string, data: { x:number, y:number }[], stack: string }[]}  */
	const series = [];
	const filters = {
		defined: !!metric.metadata.filters && metric.metadata.filters.length > 0,
		size: !!metric.metadata.filters ? metric.metadata.filters.length : 0,
		reducedSize: function () {
			return chosenFilters != null ? fun.mapKeys(chosenFilters).length : 0;
		},
		reduced: function () {
			return this.reducedSize() > 0;
		},
		generateSampleKey: function () {
			let k = [];
			for (let _ = 0; _ < this.size; _++) k.push("x");
			return k;
		},
		getFilterByIndex: function (i) {
			return metric.metadata.filters[parseInt(i)];
		},
		getFilterIndexByCode: function (code) {
			for (let i = 0; i < metric.metadata.filters.length; i++) {
				if (metric.metadata.filters[i].code === code) {
					return i;
				}
			}
		},
	};

	// -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -

	for (let d = 0; d < metric.data.length; d++) {
		const currentData = metric.data[d];

		for (let c = 0; c < chosenRegions.length; c++) {
			// Iteracia cez regiony.
			const rIndex = chosenRegions[c],
				currentRegionName = metric.metadata.regions[rIndex],
				currentRegionDataArray = currentData.regions[rIndex];

			// if data for region is missing
			if(currentRegionDataArray == undefined) {
				continue;
			}

			if (filters.defined) {
				/** @type {{ [key: string]: { name: string, stack?: string, data: { x:number, y:number }[] }}} Nasa docasna struktura. */
				const currentTemporarySeries = {};

				let isFilterCombined = false;
				if (filters.reducedSize() === 1) {
					const vals = chosenFilters[fun.mapKeys(chosenFilters)[0]].values;
					if (vals.length === 1 && isNaN(vals[0])) isFilterCombined = true;
				}

				for (let i = 0; i < currentRegionDataArray.length; i++) {
					const regionDataItem = currentRegionDataArray[i];
					/** @type {string[]} */
					const itemFilters = fun
						.mapKeys(regionDataItem)
						.filter(function (prop) {
							return prop !== "x" && prop !== "y";
						});

					let constructedKey = filters.generateSampleKey(),
						constructedKeyStr = "u",
						constructedName = currentRegionName;

					// Hotfix 02.12.2020: Ak mame nekonzistentne metadata (filters.defined) tak overime ci je to aj v datach tak
					if (itemFilters.length) constructedName += ": ";
					for (let f = 0; f < itemFilters.length; f++) {
						const itemFilterName = itemFilters[f], // tu mame "id_nace_kompas"
							itemFilterValue = regionDataItem[itemFilterName], // tu mame "1"
							filterIndex = filters.getFilterIndexByCode(itemFilterName);

						constructedKey[filterIndex] = itemFilterValue;
						/** @todo currentFilterObject.list[itemFilterValue] MOZE BYT UNDEFINED! */
						constructedName +=
							filters.getFilterByIndex(filterIndex).list[itemFilterValue] +
							" - ";
					}
					constructedKeyStr = JSON.stringify(constructedKey);

					if (itemFilters.length) {
						constructedName = constructedName.slice(0, -3);
					}

					if (currentTemporarySeries[constructedKeyStr] == null) {
						currentTemporarySeries[constructedKeyStr] = {
							name: "",
							stack: currentRegionName.replace('kraj', ''),
							data: [],
						};
					}

					// Key is constructed, we may store the series point into the correct series
					currentTemporarySeries[constructedKeyStr].data.push({
						x: fun.parseFloat(regionDataItem.x),
						y: fun.parseFloat(regionDataItem.y),
					});
					// Add name if necessary (usually happens in the first iteration)
					if (currentTemporarySeries[constructedKeyStr].name == "") {
						currentTemporarySeries[constructedKeyStr].name = constructedName;
					}
				} // @endof FOREACH {currentRegionDataArray}

				// currentTemporarySeries (CTS) je naplneny, je to sparse array tak vyfiltruj undefined vals a appendni do main series
				const ctsKeys = fun.mapKeys(currentTemporarySeries);
				for (let i = 0; i < ctsKeys.length; i++) {
					const currentKey = ctsKeys[i];

					if (filters.reduced() && !isFilterCombined) {
						/** @type {string[]} */
						const currentParsedKey = JSON.parse(currentKey);
						const cfk = fun.mapKeys(chosenFilters);
						for (let cfi = 0; cfi < cfk.length; cfi++) {
							const reductionCode = cfk[cfi],
								reductionObject = chosenFilters[reductionCode],
								filterIndex = filters.getFilterIndexByCode(reductionCode);
							if (filterIndex == null) continue;

							let isContainedInReducedFilter = false;
							for (let ai = 0; ai < reductionObject.values.length; ai++) {
								// {allowIndex}
								if (
									currentParsedKey[filterIndex] ===
									reductionObject.values[ai].toString()
								) {
									isContainedInReducedFilter = true;
								}
							}
							if (!isContainedInReducedFilter) {
								delete currentTemporarySeries[currentKey];
								break;
							}
						} // @endof FOREACH {cfk}
					} // @endof IF {filters.reduced}

					if (currentTemporarySeries[currentKey] == null) continue;
					series.push(currentTemporarySeries[currentKey]);
				} // @endof FOREACH {ctsKeys}
			} // @endof IF {filters.defined}
			else {
				/** @type {{ x:number, y: number }[]} */
				const transformedSeries = [];
				for (let i = 0; i < currentRegionDataArray.length; i++) {
					transformedSeries.push({
						x: fun.parseFloat(currentRegionDataArray[i].x),
						y: fun.parseFloat(currentRegionDataArray[i].y),
					});
				}
				series.push({
					name: currentRegionName,
					data: transformedSeries,
				});
			} // @endof IF {!filters.defined}
		} // @endof FOREACH {chosenRegions}
	} // @ endof FOREACH {metric.data}

	// Hotfix 04.01.2020 ~ Ensure graph values are ascending by domain
	for (let i = 0; i < series.length; i++) {
		series[i].data = series[i].data.sort(function (a, b) {
			return a.x - b.x;
		});
	}

	return {
		titles: {
			main: "", // I guess this ain't used
			xAxis: metric.metadata.domainTitle,
			yAxis: metric.data[0].name,
		},
		series: series,
	};
}

/**
 * 
 * @param {{
 * metadata: {
 *   domain: string[]
 *   domainTitle: string
 * 
 *   prediction: string | number
 *   currentYear: string
 * 
 *   chartType: string
 *   exportFilename: string
 *   note: string
 *   height: number
 * 
 *   requireRegionSelection: boolean
 *   showMapView: boolean
 *   stacked: boolean
 * 
 *   filters: { code: string; name: string; list: { [key: string]: string } }[]
 *   regions: { [key: number]: string }
 *   mapRegions: { [key: string]: string }
 * }
 * data: {
 *   name: string
 *   regions: {
 *     [key: string]: string
 *     x: string
 *     y: string 
 *   }[][]
 * }[]
 * }} metric
 * @returns {{
      name: string
      data: [string, number][]
    }[]}
 */
function processMapData(metric) {
	/**
	 * (-2) stands for attributes 'x' and 'y'
	 * @param {{ [key: string]: string; x: string; y: string; }} node
	 * @returns {number}
	 */
	const numberOfFilters = function (node) {
		return fun.mapKeys(node).length - 2;
	};

	/**
	 * Return the name of node main filter. IT IS EXPECTED THAT NODE HAS ONLY ONE FILTER
	 * @param {{ [key: string]: string; x: string; y: string; }} node
	 * @returns {string}
	 */
	const getFilterName = function (node) {
		const attributes = fun.mapKeys(node);
		for (let i = 0; i < attributes.length; i++) {
			if (attributes[i] === "x" || attributes[i] === "y") continue;
			return attributes[i];
		}
	};

	const regionsKeys =  Object.keys(metric.data[0].regions);
	const firstRegionKey = regionsKeys[0];
	const hasValidFilterRules =
		numberOfFilters(metric.data[0].regions[firstRegionKey][0]) === 1;
	const dataSource = metric.data[0];
	const chosenYear = metric.metadata.currentYear;
	/** @type {{ name: string, data: [id: string, value: number][] }} */
	const mapSeriesObject = {
		name: dataSource.name,
		data: [],
	};

	const regionsCount = metric.metadata.requireRegionSelection ? regionsKeys.length : (regionsKeys.length - 1);
	for (
		let regionID = 1;
		regionID <= regionsCount;
		regionID++
	) {
		const regionMapID = metric.metadata.mapRegions[regionID.toString()];
		let regionMapValue = 0;
		const regionDataArray = dataSource.regions[regionID];

		for (let i = 0; i < regionDataArray.length; i++) {
			const node = regionDataArray[i];
			if (node.x === chosenYear) {
				const filterName = getFilterName(node);
				if (
					gFilters[filterName] &&
					gFilters[filterName].values.includes(parseInt(node[filterName]))
				) {
					regionMapValue = fun.parseFloat(node.y);
					break;
				}
			}
		}

		mapSeriesObject.data.push([regionMapID, regionMapValue]);
	}

	const mockedDataSeries = [
		{
			name: "",
			data: [
				["cz-2293", 0],
				["cz-6305", 0],
				["cz-6306", 0],
				["cz-6307", 0],
				["cz-6308", 0],
				["cz-kk", 0],
				["cz-ck", 0],
				["cz-jk", 0],
				["cz-sk", 0],
				["cz-lk", 0],
				["cz-hk", 0],
				["cz-vk", 0],
				["cz-6303", 0],
				["cz-6304", 0],
			],
		},
	];

	const createdSeries = [mapSeriesObject];
	// Jo musim to zabalit do array aby to ta kniznica spracovala dobre..
	return hasValidFilterRules ? createdSeries : mockedDataSeries;
}

/**
 * Main highcharts function for rendering the data.
 * @param {JQuery<Element>} wrapper
 * @param {JQuery<Element>} mapWrapper
 * @param {JQuery<Element>} metricGroup
 * @param {{
 * id: string
 * metricId: number
 * metricData: {
 *   metadata: {
 *     domain: string[]
 *     domainTitle: string
 * 
 *     prediction: string | number
 *     currentYear: string
 * 
 *     chartType: string
 *     exportFilename: string
 *     note: string
 *     height: number
 * 
 *     requireRegionSelection: boolean
 *     showMapView: boolean
 *     stacked: boolean
 * 
 *     filters: { code: string; name: string; list: { [key: string]: string } }[]
 *     regions: { [key: number]: string }
 *     mapRegions: { [key: string]: string }
 *   }
 *   data: {
 *     name: string
 *     regions: {
 *       [key: string]: string
 *       x: string
 *       y: string
 *     }[][]
 *   }[]
 * }
 * }} data
 */
function renderChart(wrapper, mapWrapper, metricGroup, data) {
	// Prevents unnecessary throws.
	if (!data || data.metricData == null || !data.metricData.metadata) return;

	const processed = processMetricData(data.metricData, gRegions, gFilters),
		domain = data.metricData.metadata.domain,
		chartType = data.metricData.metadata.chartType,
		plotBands = chartType != "line",
		prediction = data.metricData.metadata.prediction,
		height = data.metricData.metadata.height
			? data.metricData.metadata.height
			: 320,
		showMap = data.metricData.metadata.showMapView
			? data.metricData.metadata.showMapView
			: false;

	Highcharts.setOptions({
		lang: {
			numericSymbols: ["t", "mil", "mld"],
			decimalPoint: ","
		},
	});
	
	const chartConfig = {
		series: processed.series,
		chart: {
			type: chartType,
			styledMode: true,
			colorCount: 8,
			height: height,
			events: {
				render: function (e) {
					let chart = e.target;
					wrapper.trigger("chart-render", [chart]);
				},
			},
			numberFormatter: function(number) {
				return Highcharts.numberFormat(number, -1,',','');
			} 
		},
		plotOptions: {
			series: {
				zoneAxis: "x",
				zones: [{ value: prediction }, { dashStyle: "dash" }],
				stacking: data.metricData.metadata.stacked,
				marker: { enabled: false, symbol: "circle", radius: 2 },
			},
		},
		legend: {
			useHTML: true,
			alignColumns: false,
			floating: true,
			labelFormatter: function () {
				return (
					'<span class="highcharts-legend-item-text">' + this.name + "</span>"
				);
			},
		},
		tooltip: {
			useHTML: true,
			borderWidth: 0,
			borderRadius: 7,
			style: { padding: 0 },
		},
		title: { text: processed.titles.main },
		lang: { decimalPoint: ',' },
		xAxis: {
			title: { text: processed.titles.xAxis, useHTML: true },
			allowDecimals: false,
			tickInterval: 1,
			labels: { step: 1, useHTML: true },
			plotBands: [
				{
					from: plotBands ? prediction : null,
					to: plotBands ? domain[domain.length - 1] + 1 : null,
				},
			],
		},
		yAxis: {
			title: { text: processed.titles.yAxis, useHTML: true },
			labels: { useHTML: true },
			stackLabels: data.metricData.metadata.stacked
				? {
						enabled: true,
						allowOverlap: true,
						useHTML: true,
						//rotation: -90,
						textAlign: 'left',
						align: 'left',
						formatter: function () {
							return this.stack;
						},
						//verticalAlign: 'bottom'
				  }
				: undefined,
		},
		exporting: {
			enabled: false,
			showTable: true,
			tableCaption: "",
			filename: data.metricData.metadata.exportFilename
				? data.metricData.metadata.exportFilename
				: "export",
			csv: { itemDelimiter: ";", lineDelimiter: '\n', decimalPoint: ',' },
		},
		credits: {
			enabled: false,
		},
	};

	// If region selection is required (and only average is selected) - show empty chart with the selection requirement
	// region selection message
	const regionSelectionMessage = metricGroup.find('.metric-region-selection-msg');
	const metricDataWrapper = metricGroup.find('.metric-data-wrapper');
	if (data.metricData.metadata.requireRegionSelection && gRegions.includes(0) && (gRegions.length === 1)) {
		// add empty series to make empty chart
		const emptySerie = new Array(domain.length).fill(null);
		chartConfig.series = [
			{
			  data: emptySerie
			}
		  ];
		chartConfig.legend.enabled = false;
		chartConfig.yAxis.min = 0;
		chartConfig.yAxis.max = parseInt(data.metricData.metadata.maxValue);
		chartConfig.xAxis.labels.formatter = function() {
			return domain[this.value];
		}
		// blur chart / table / map
		if(metricDataWrapper.length) {
			metricDataWrapper.addClass('metric-blurred');
		}
		// show message
		if(regionSelectionMessage.length) {
			regionSelectionMessage.fadeIn(300);
		}

	}
	else {
		if(regionSelectionMessage.length) {
			regionSelectionMessage.hide();
		}
		if(metricDataWrapper.length) {
			metricDataWrapper.removeClass('metric-blurred');
		}
	}

	// init chart
	wrapper.highcharts(chartConfig);

	const mapSeries = processMapData(data.metricData);
	if (showMap && mapSeries) {
		//map initialization
		mapWrapper.highcharts("Map", {
			series: mapSeries,
			exporting: { enabled: false },
			legend: {
				layout: "vertical",
				useHTML: true,
				enabled: true,
				verticalAlign: "middle",
				align: "right",
			},
			title: { text: "" },
			chart: {
				map: "countries/cz/cz-all",
				styledMode: true,
			},
			colorAxis: {
				minColor: "#7fbdff",
				maxColor: "#0030a3",
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: true,
						format: "{point.name}",
					},
				},
			},
			credits: {
				enabled: false,
			},
		});
	} else {
		mapWrapper.remove();
		$(".metric-map").hide();
	}

	// init legend show/hide button
	initLegendToggle(wrapper, data.metricData.metadata.note);
	formatTableNumbers(wrapper);
}

function initLegendToggle(wrapper, note) {
	const legend = wrapper.find("div.highcharts-legend"),
		items = wrapper.find("div.highcharts-legend-item"),
		count = wrapper.data("legend-max-items")
			? wrapper.data("legend-max-items")
			: 6,
		triggerTextMore = wrapper.data("legend-trigger-more")
			? wrapper.data("legend-trigger-more")
			: "Zobrazit vše",
		triggerTextLess = wrapper.data("legend-trigger-less")
			? wrapper.data("legend-trigger-less")
			: "Skrýt",
		trig = $(
			'<span class="highcharts-legend-toggle">' + triggerTextMore + "</span>"
		);

	if (!legend.length || !items.length) return;
	if (items.length > count) {
		const toggleItems = items.slice(count);

		legend.append(trig);
		toggleItems.hide();

		trig.on("click", function () {
			toggleItems.fadeToggle();
			trig.toggleClass("active");
			trig.text(trig.hasClass("active") ? triggerTextLess : triggerTextMore);
		});
	}
	if(note != undefined && note != '') {
		legend.prepend('<p class="highcharts-legend-note">'+note+'</p>');
	}
}

function formatTableNumbers(wrapper) {
	const table = wrapper.closest('.metric-group').find('.highcharts-data-table tbody');
	if(table.length) {
		table.html(table.html().replace(/(\d+)\.(\d+)/g, '$1,$2'));
	}
}

/**
 * Global variable metric data.
 * @type {{
 * [key: string]: {
 *   id: string
 *   metricId: string
 *   metricData: {
 *     metadata: {
 *       chartType: string
 *       currentYear: string
 *       domain: string[]
 *       domainTitle: string
 *       exportFilename: string
 *       filters: { code: string; name: string; list: { [key: string]: string } }[]
 *       height: number
 *       mapRegions: { [key: string]: string }
 *       prediction: string | number
 *       regions: { [key: number]: string }
 *       showMapView: boolean
 *       stacked: boolean
 *     }
 *     data: {
 *       name: string
 *       regions: { [key: string]: string; x: string; y: string }[][] }[]
 *     }
 *   }
 * }}
 */
const gMetricDataRoot = {};
/** @type {{ [key: string]: { values: number[] } }} Global page selection of filters (professions/sectors/education) */
let gFilters = {};
/** @type {number[]} Global page selection of regions*/
let gRegions = [];


/** @type {number[]} Global page selection of filters (professions/sectors/education) - as backend data object ids */
let gFiltersBackendIds = [];
/** @type {number[]} Global page selection of regions - as backend data object ids */
let gRegionsBackendIds = [];
