$(document).ready(function ($) {
	$('.to-anchor').scrollToAnchor();

	// anchors in accordions
	$('.accordions-anchors a[href*="#"').each(function() {
		let link = $(this),
			target = $(link.attr('href'));
		if(!target.length) return;

		let targetAccordion = target.closest('.accordion');
		if(!targetAccordion.length) return;


		link.on('click', function() {
			event.preventDefault();

			// open closed accordion
			let targetAccordionTrig = targetAccordion.find('.sh-trig:not(.active)');
			if(targetAccordionTrig.length) {
				targetAccordionTrig.trigger('click');
			}

			// scroll to anchor
			var destination = targetAccordion.offset().top;

			$("html:not(:animated),body:not(:animated)").animate(
					{scrollTop: destination},
					500, function () {
						history.pushState(null, null, link.attr('href'));
					}
			);
		});
	});
});

// Plynulý scroll ke kotvě
(function ($) {
	$.fn.scrollToAnchor = function (settings) {

		settings = jQuery.extend({
			speed: 500
		}, settings);

		return this.each(function () {
			var caller = this;
			$(caller).click(function (event) {
				var elementClick = $(caller).attr("href");
				if (elementClick.length > 1 && elementClick.charAt(0) == '#') {
					event.preventDefault();

					var destination = $(elementClick).offset().top;

					$("html:not(:animated),body:not(:animated)").animate(
							{scrollTop: destination},
							settings.speed, function () {
								history.pushState(null, null, elementClick);
							}
					);

					return false;
				}
			});
		});
	};
})(jQuery);

// Loader counter
$(function () {
	var total = $('.counter').data('total'),
			subscribed = $('.counter').data('subscribed'),
			elW = (subscribed / total) * 100;

	$('.counter').find('.load').animate(
			{width: elW + '%'}, {
		duration: 2000,
		step: function (now, fx) {
			if (fx.prop == 'width') {
				$(this).find('.num').html(Math.round(now / 100 * total));
				$('.counter .remains').html(Math.round(total - (now / 100 * total)));
			}
		}
	}
	);
});

// Timer od 0
(function ($) {
	$.fn.countTo = function (options) {
		options = $.extend({}, $.fn.countTo.defaults, options || {});

		var loops = Math.ceil(options.speed / options.refreshInterval);

		$(this).each(function () {
			var _this = this,
					loopCount = 0,
					value = options.from,
					interval = setInterval(updateTimer, options.refreshInterval),
					amount = $(this).data('amount'),
					increment = (amount - options.from) / loops,
					plus = '';
			if ($(this).data('plus')) {
				plus = '<span class="plus">+</span>';
			}

			function updateTimer() {
				value += increment;
				loopCount++;
				$(_this).html(value.toFixed(options.decimals) + plus);

				if (typeof (options.onUpdate) == 'function') {
					options.onUpdate.call(_this, value);
				}

				if (loopCount >= loops) {
					clearInterval(interval);
					value = amount;

					if (typeof (options.onComplete) == 'function') {
						options.onComplete.call(_this, value);
					}
				}
			}
		});
	};

	$.fn.countTo.defaults = {
		from: 0,
		speed: 1000,
		refreshInterval: 100,
		decimals: 0,
		onUpdate: null,
		onComplete: null
	};
})(jQuery);
