jQuery(function() {
	$('.topics-selects').each(function() {
		var topicsSelects = $(this),
			topicsSelectsWrapper = $(this).find('.topics-select-wrapper'),
			dropdownWrapper = $(this).find('.topics-select-dropdown-wrapper'),
			saveButton = dropdownWrapper.find('.topics-selects-save'),
			cancelButton = dropdownWrapper.find('.topics-selects-cancel'),
			selects = [];

		if(topicsSelectsWrapper.length && dropdownWrapper.length) {
			// selects
			topicsSelectsWrapper.each(function() {
				var selectWrapper = $(this),
					select = $(this).find('.topics-select'),
					dropdown = $($(this).data('dropdown')),
					defaultOption = $(this).find('option[data-default="true"]'),
					placeholder = $(this).data('placeholder');
				if(select.length && dropdown.length) {
					select.select2({
						closeOnSelect: false,
						selectOnClose: false,
						placeholder: '',
						dropdownParent: dropdown,
						templateSelection: function(item) {
							var url = item.element.dataset.url,
								detailText = item.element.dataset.detailText ? item.element.dataset.detailText : item.text;
							if (!url) {
								return $('<span class="select2-selection__choice__text">' + item.text + '</span>');
							}
							var $item = $(
								'<span class="select2-selection__choice__text">' + item.text + '</span>'
								+'<a class="select2-selection__choice__link" href="' + url +'" title="' + item.text + '" target="_self">' + detailText + '</a>'
							);
							return $item;
						}
					});
					selects.push(select);

					// store last selected option, to handle selecting/unselecting default option
					select.on('select2:selecting', function(e){
						select.data('last-selected', e.params.args.data.element.value);
					});

					// select default option if no other options are selected / eventually unselect default option
					if(defaultOption.length) {
						select.bind('change', {select: select, defaultOption: defaultOption}, selectDefaultOption);
						select.trigger('change');
					}

					// store selected values to restore them when cancelled
					select.bind('select2:open', {select: select}, storeSelectedValues);
					select.bind('select2:close', {select: select}, discardSelectedValues);

					// prevent dropdown closing (close only with buttons)
					select.bind('select2:closing', {select: select, selectWrapper: selectWrapper}, preventDropdownClosing);

					// handle dropdown opening and closing (open/close all dropdowns at the same time)
					select.bind('select2:open', {select: select, selects: selects, selectWrapper: selectWrapper}, handleDropdownOpen);
					select.bind('select2:close', {select: select, selects: selects, selectWrapper: selectWrapper}, handleDropdownClose);

					// add "modal" overlay to body when dropdown opened
					select.on('select2:open', function() { $('body').addClass('modal-opened select-opened'); });
					select.on('select2:close', function() { $('body').removeClass('modal-opened select-opened'); });

					// set placeholder to data attribute, to show using css, visible even with selected values
					let search = $(this).find('.select2-search');
					if(search.length) {
						search.attr('data-placeholder', placeholder);
					}

					// hide dropdowns when inputs switching
					let searchInput = $(this).find('.select2-selection');
					if(searchInput.length) {
						searchInput.on('click', function() {
							dropdown.siblings().hide();
							selectWrapper.siblings().removeClass('topics-select-wrapper-active');

							dropdown.show();
							selectWrapper.addClass('topics-select-wrapper-active');
						});
					}

					// dropdown save/cancel buttons, close and save selection of each select
					if(saveButton.length && cancelButton.length) {
						saveButton.on('mousedown', function(e) {
							e.stopImmediatePropagation();
						});
						cancelButton.on('mousedown', function(e) {
							e.stopImmediatePropagation();
						});
						//cancelButton.off('click');
						cancelButton.on('click', function(e) {
							if(select.selectedValues) {
								let data = select.selectedValues;
								select.val(data);
								select.trigger('change');
								select.forceClose = true;
								select.select2("close");
							}
						});
						//saveButton.off('click');
						saveButton.on('click', function(e) {
							select.forceClose = true;
							select.select2("close");
							select.trigger('selection-save');
						});
					}
				}
			});

			// trigger selection save on wrapper
			if(saveButton.length) {
				saveButton.on('click', function(e) {
					topicsSelects.trigger('all-selection-save');
				});
			}

			// close dropdown after click out of selects area
			$('body').on('click', function(e) {
				var selectionSaved = false;

				selects.forEach(function(select) {
					if(!select.data('select2').isOpen()) return;

					const isOutOfArea = !$('.topics-selects, .topics-select, .select2-selection__choice__remove').is(e.target) && $('.topics-selects, .topics-select, .select2-selection__choice__remove').has(e.target).length === 0,
						targetClasses = e.target.classList;
					if (isOutOfArea && !(targetClasses.contains('select2-selection__choice__remove')) && !(targetClasses.contains('metric-chart'))) {
						selectionSaved = true;
						select.forceClose = true;
						select.select2("close");
						select.trigger('selection-save');
					}
				});

				if(selectionSaved) {
					topicsSelects.trigger('all-selection-save');
				}
			});
		}
	});
});

var storeSelectedValues = function (event) {
	const select = event.data.select;
	if(select.length) {
		const data = select.val();
		select.selectedValues = data;
	}
}

var discardSelectedValues = function (event) {
	const select = event.data.select;
	if(select.length && select.selectedValues) {
		select.selectedValues = null;
	}
}

var preventDropdownClosing = function (event) {
	const select = event.data.select,
		wrapper = event.data.selectWrapper,
		input = wrapper.find('.select2-search__field');

	if (!select.length) return;

	if(select.forceClose) {
		select.forceClose = null;
	}
	else {
		event.preventDefault();
		if(input.length) {
			input.trigger('focus');
		}
	}
}

var handleDropdownClose = function (event) {
	const wrapper = event.data.selectWrapper,
		input = wrapper.find('.select2-search__field');

	// unfocus input
	if(input.length) {
		input.trigger('blur');
	}
}

var handleDropdownOpen = function (event) {
	const select = event.data.select,
		allSelects = event.data.selects,
		wrapper = event.data.selectWrapper,
		input = wrapper.find('.select2-search__field');

	// open also siblings selects
	if (allSelects.length <=1 || !select.length) return;
	allSelects.forEach(function(s) {
		if(s != select && !s.data('select2').isOpen()) {
			s.select2('open');
		}
		
	});
	if(input.length) {
		input.trigger('focus');
	}
}

var selectDefaultOption = function (event) {
	const select = event.data.select,
		defaultOption = event.data.defaultOption;
	if(!select.length || !defaultOption.length) return;
	
	// if there is no option selected, select default option
	if(!select.val().length) {
		select.val(defaultOption.val());
		select.trigger('change');
	}
	// if some option is selected, and default option should be exclusive
	else if(select.val().length > 1 && defaultOption.data('default-exclusive') && defaultOption.prop('selected')) {
		// if selecting default option, unselect other options
		if(select.data('last-selected') == defaultOption.val()) {
			select.val(defaultOption.val());
			select.trigger('change');
		}
		// if selecting some other option, unselect the default
		else {
			defaultOption.prop('selected', false);
			select.trigger('change');
		}
	}
}