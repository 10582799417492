jQuery(function(){
	const scrollbar = document.querySelector('.document-scrollbar');

	if(scrollbar !== null) {
		const container = scrollbar.closest('.document-scrollbar-container');
		if(container == null) return;

		const scrollbarContainerWidth = container.offsetWidth;
		const widthToScroll = document.body.scrollWidth - document.body.clientWidth;
		const scrollbarWidth = Math.max(document.body.clientWidth / document.body.scrollWidth * scrollbarContainerWidth, 600);
		scrollbar.style.width = scrollbarWidth + 'px';

		if(widthToScroll <= 0) return;

		var isDown = false;
		var startX;
		var startScroll;

		window.addEventListener('scroll', () => {
			const newScrollPercents = (window.pageXOffset > 0) ? (window.pageXOffset / widthToScroll) : 0;
			const newScrollbarPosition = newScrollPercents * scrollbarContainerWidth;
			const scrollbarWidthOffset = newScrollPercents * scrollbarWidth;

			scrollbar.style.left = (Math.min(newScrollbarPosition, scrollbarContainerWidth) - scrollbarWidthOffset) + 'px';
		});
		window.dispatchEvent(new CustomEvent('scroll'));

		window.addEventListener('mousedown', (e) => {
			if(event.target.closest('.document-scrollbar-container')) {
				isDown = true;
			}
			else {
				return;
			}

			// set start cursor and scrollbar position
			startX = e.clientX;
			startScroll = window.scrollX;
		});
		window.addEventListener('mouseleave', () => {
			isDown = false;
		});
		window.addEventListener('mouseup', () => {
			isDown = false;
		});
		window.addEventListener('mousemove', (e) => {
			if(!isDown) return;
			e.preventDefault();

			// calculate difference
			const x = e.clientX;
			const walk = (x - startX) * (document.body.scrollWidth / document.body.clientWidth);

			// calculate new position
			const newX = startScroll + walk;

			// move the scrollbar and scroll the page, respecting the borders
			window.scrollTo({
				top: window.scrollY,
				left: Math.min(newX, widthToScroll)
			});
		});

		document.documentElement.classList.add('document-scrolled');
		container.classList.add('document-scrollbar-active');
	}
});